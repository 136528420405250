import { ref } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import moment from 'moment'

export default function editProduct() {
  const vendorSelected = ref(null)
  const categorySelected = {}
  const idProduct = ref(null)
  const categogies = []
  const imageHeader = ref([])
  const imagesHowItWork = ref([])
  const imageOverview = ref([])
  const imageHeaderOnEdit = ref([])
  const imagesHowItWorkOnEdit = ref([])
  const imageOverviewOnEdit = ref([])
  const arrayDescription = []
  const arrayDescriptionOnEdit = []
  const productEdit = ref({
    title: '',
    slug: '',
    tag_ids: [],
    category_id: {},
    type: {},
    form_price: '',
  })
  const count = ref(null)
  const productOnEdit = ref({
    title: '',
    slug: '',
    tag_ids: [],
    category_id: {},
    type: {},
    form_price: '',
  })
  const countOnEdit = ref(null)

  idProduct.value = router.currentRoute.params.id
  setTimeout(() => {
    store
      .dispatch('product/fetchProduct', { id: idProduct.value })
      .then(response => {
        const responseEdit = response.body
        productEdit.value = {
          region_id: responseEdit.region_id,
          title: responseEdit.title,
          tag_ids: responseEdit.tags.map(x => ({
            label: x.name,
            value: x.id,
          })),
          from_price: responseEdit.price ? responseEdit.price.from_price : 0,
          slug: responseEdit.slug,
          category_ids: responseEdit.categories.map(x => ({
            label: `[${x.region_id?.toUpperCase()}]-${x.name}`,
            value: x.id,
          })),
          product_roles: responseEdit.product_roles.map(x => ({
            label: `[${x.region_id?.toUpperCase()}]-${x.name}`,
            value: x.id,
          })),
          product_bussiness_challenges: responseEdit.product_bussiness_challenges.map(x => ({
            label: `[${x.region_id?.toUpperCase()}]-${x.name}`,
            value: x.id,
          })),
          startDateTime: moment(responseEdit.metadata.metadata.startDatetime).format('YYYY-MM-DD hh:mm'),
        }
        // Fetch enities by region
        if (productEdit.value.region_id) {
          const queryParams = {
            region_ids: [productEdit.value.region_id],
          }
          store.dispatch('bussiness/fetchBussiness', queryParams)
          store.dispatch('role/fetchRole', queryParams)
          const queryParamsCate = {
            region: productEdit.value.region_id,
          }
          store.dispatch('product/fetchCategories', queryParamsCate)
        }
        count.value = responseEdit.metadata.metadata.regions_form.length
        responseEdit.metadata.metadata.regions_form.forEach(e => {
          arrayDescription.push(e)
        })
        if (responseEdit.images.length > 0) {
          responseEdit.images.forEach(x => {
            if (x.type === 'header_image') {
              imageHeader.value.push(x)
            } else if (x.type === 'how_it_work') {
              imagesHowItWork.value.push(x)
            }
          })
        }
        // Get image overview
        imageOverview.value.push(
          responseEdit.metadata.metadata.imageOverview,
        )
        if (response.body.product_on_edit.data) {
          const responseOnEdit = response.body.product_on_edit.data
          productOnEdit.value = {
            region_id: responseEdit.region_id,
            title: responseOnEdit.title,
            tag_ids: responseOnEdit.tag_ids.map(x => ({
              label: store.state.product.productTags.length > 0 ? store.state.product.productTags.find(
                i => x === i.value,
              ).label : '',
              value: x,
            })),
            price: responseOnEdit.from_price,
            slug: responseOnEdit.slug,
            category_ids: responseOnEdit.category_ids.map(x => ({
              label: store.state.product.productCategory.length > 0 ? store.state.product.productCategory.find(
                i => x === i.value,
              ).label : '',
              value: x,
            })),
            product_roles: responseOnEdit.product_role_ids.map(x => ({
              label: store.state.role.productRole.length > 0 ? store.state.role.productRole.find(
                i => x === i.value,
              ).label : '',
              value: x,
            })),
            product_bussiness_challenges: responseOnEdit.product_bussiness_challenge_ids.map(x => ({
              label: store.state.bussiness.productBussiess.length > 0 ? store.state.bussiness.productBussiess.find(
                i => x === i.value,
              ).label : '',
              value: x,
            })),
            startDateTime: moment(responseOnEdit.metadata.startDatetime).format('YYYY-MM-DD hh:mm'),
          }
          countOnEdit.value = responseOnEdit.metadata.regions_form.length
          responseOnEdit.metadata.regions_form.forEach(i => {
            arrayDescriptionOnEdit.push(i)
          })

          if (responseOnEdit.medias.length > 0) {
            responseOnEdit.medias.forEach(x => {
              if (x.type === 'header_image') {
                imageHeaderOnEdit.value.push(x)
              } else if (x.type === 'how_it_work') {
                imagesHowItWorkOnEdit.value.push(x)
              }
            })
          }
          // Get image overview
          imageOverviewOnEdit.value.push(
            responseOnEdit.metadata.imageOverview,
          )
        }
      })
      .catch(error => {
        console.log(error)
      })
  }, 0)
  console.log(productEdit)

  const updateItemForm = (index, val) => {
    const regionId = val
    arrayDescription[index].region_id = regionId
  }
  return {
    arrayDescriptionOnEdit,
    countOnEdit,
    productOnEdit,
    imageHeader,
    imagesHowItWork,
    imageOverviewOnEdit,
    imageHeaderOnEdit,
    imagesHowItWorkOnEdit,
    productEdit,
    categogies,
    idProduct,
    arrayDescription,
    count,
    updateItemForm,
    vendorSelected,
    categorySelected,
    imageOverview,
  }
}
